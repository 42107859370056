<template>
  <site-layout>
    <v-row class="mt-8">
      <v-col cols="12" md="6">
        <section-start></section-start>
        <template v-if="loading">
          <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          <v-skeleton-loader type="list-item-three-line@4"></v-skeleton-loader>
        </template>
        <template v-else>
          <span class="text-h4">{{ body.title }}</span><br>
          <span class="font-weight-bold black--text">{{ body.subtitle }}</span>
          <div class="mt-5">
            <titled-paragraph :title="body.subtitle" v-html="body.content"></titled-paragraph>
          </div>
        </template>
      </v-col>
      <v-col cols="12" md="6">
        <template v-if="loading">
          <v-skeleton-loader type="image@2"></v-skeleton-loader>
          <v-row class="mt-3">
            <v-col cols="3" v-for="n in 4" :key="n">
              <v-skeleton-loader type="image" max-height="100px"></v-skeleton-loader>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-carousel :height="sliderHeight" hide-delimiters v-model="slide">
            <v-carousel-item v-for="(media, mediaIdx) in media" :key="mediaIdx" contain :src="media.url ? media.url : media" @click="showDialog(media)"></v-carousel-item>
          </v-carousel>
          <v-row>
            <template v-for="(media, mediaIdx) in media">
              <v-col cols="6" md="3"  :key="mediaIdx" v-if="slide !== mediaIdx">
                <div class="postimg-container clickable">
                  <v-img :src="media.url ? media.url : media" height="100px" class="postimg" @click="goToSlide(mediaIdx)"></v-img>
                </div>
              </v-col>
            </template>
          </v-row>
          <v-card flat class="d-flex align-end mt-5">
            <v-spacer></v-spacer>
            <router-link :to="{name: 'Post', params: {type: $route.params.type}}" class="font-weight-bold">Terug naar het overzicht</router-link>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-dialog :value="dialogMedia != null" @input="dialogMedia = null" class="mediadialog" max-width="1750px">
      <v-card style="background-color: transparent" flat elevation="0">
        <v-img :src="dialogMedia"></v-img>
        <v-card-actions style="background-color: transparent">
          <v-spacer></v-spacer>
          <v-btn icon color="white" @click="dialogMedia = null"><v-icon large>mdi mdi-close</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </site-layout>
</template>

<script>
import SiteLayout from "../layouts/SiteLayout";
import SectionStart from "../components/SectionStart";
import TitledParagraph from "../components/TitledParagraph";
import http from "../http";

export default {
  name: "ViewPost",
  components: {TitledParagraph, SectionStart, SiteLayout},
  data() {
    return {
      media: [],
      body: {},
      dialogMedia: null,
      slide: 0,
      loading: true
    }
  },
  methods: {
    showDialog(x) {
      this.dialogMedia = x;
    },
    goToSlide(index) {
      this.slide = index;
    }
  },
  computed: {
    sliderHeight() {
      if (this.$vuetify.breakpoint.smAndDown)
        return '320px';
      return '400px';
    }
  },
  async mounted() {
      const resp = await http.get(`/p/${this.$route.params.slug}`);
      this.media = [resp.data.data.featured_image, ...resp.data.data.media];
      this.body = resp.data.data.content.find(x => x.code === 'nl');
      this.loading = false;
  }
}
</script>

<style>

</style>
