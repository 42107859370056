<template>
  <site-layout>
    <v-row>
      <template v-if="loading">
        <v-col cols="12" sm="4" v-for="n in 9" :key="n">
          <v-skeleton-loader type="image, article" class="rounded-0"></v-skeleton-loader>
        </v-col>
      </template>
      <template v-else>
        <v-col v-if="items.length === 0" class="text-center my-10 py-10">
          <h1>{{ $t('no_items') }}</h1>
        </v-col>
        <template v-for="(item, itemIdx) in items">
          <v-col cols="12" sm="12" :key="itemIdx + 1000 + 10000 * itemIdx" v-if="shouldShowDate(itemIdx)"><h1 class="font-weight-medium">{{ itemYear(itemIdx) }}</h1></v-col>
          <v-col :key="itemIdx" cols="12" sm="4">
            <post-item
                :src="item.featured_image"
                :caption="item.title"
                :title="item.subtitle"
                :to="{name: 'ViewPost', params: {type: $route.params.type, slug: item.id}}"
                :id="item.id"
            >
              {{ item.excerpt }}
            </post-item>
          </v-col>
        </template>

      </template>
    </v-row>
    <v-row v-if="hasMore" style="position:relative;">
      <v-overlay :value="loadMoreLoading" absolute>
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-col >
        <v-card flat class="d-flex align-center justify-center flex-column clickable"  @click="loadMore">
          <span class="text-uppercase" style="font-size: 1.2rem;">{{ $t('meer_berichten') }}</span>
          <v-icon>mdi-chevron-down</v-icon>
        </v-card>
      </v-col>
    </v-row>
  </site-layout>
</template>

<script>
import SiteLayout from "../layouts/SiteLayout";
import PostItem from "../components/PostItem";
import http from "../http";
export default {
  name: "Nieuws",
  components: {PostItem, /*TitledParagraph, SectionStart,*/ SiteLayout},
  data() {
    return {
      items: [],
      meta: null,
      loading: true,
      loadMoreLoading: false
    }
  },
  methods: {
    async loadMore() {
      if (!this.hasMore)
        return;
      this.loadMoreLoading = true;
      let resp = await http.get(`/p/${this.$route.params.locale}/${this.$route.params.type}?count=9&page=` + (this.meta.current_page+1) + "&order=created_at");
      this.items = [...this.items, ...resp.data.data];

      this.meta = resp.data.meta;
      this.loadMoreLoading = false;
      this.$nextTick(() => {
        document.querySelector(".footer .row:last-of-type").scrollIntoView({behavior: 'smooth'});
      });
    },
    shouldShowDate(idx) {
      if (idx === 0) return true;
      return new Date(this.items[idx - 1].created_at).getFullYear() !== new Date(this.items[idx].created_at).getFullYear();
    },
    itemYear(idx) {
      return new Date(this.items[idx].created_at).getFullYear();
    },
    mapItem(i) {
      return {...i, created_at: new Date(i.created_at)};
    }
  },
  computed: {
    hasMore() {
      if (!this.meta) return false;
      return this.meta.current_page !== this.meta.last_page;
    }
  },
  async mounted() {
    let resp = await http.get(`/p/${this.$route.params.locale}/${this.$route.params.type}?count=9&order=created_at`);
    this.items = resp.data.data;
    this.meta = resp.data.meta;
    this.loading = false;
  },
}
</script>
